import React, { Component } from "react";

class AuditModification extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        try {
            var modification = this.props.modification

            return (
                <div className="auditModification"> 
                    <span>Change Type: {modification.customData.action} </span>
                    <span>Time: {modification.customData.updatedAtCorrected} </span>
                    <span>Text: {modification.text} </span>
                </div>
                )
        } catch (e) {
            console.log(e);
            return('');
        }

    }
}

export default AuditModification;
